import { graphql } from "gatsby"
import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SearchPage from "../components/SearchPage"

export const query = graphql`
  query {
    allTopicsYaml {
      edges {
        node {
          id
          title
          slug: path
          articles
        }
      }
    }

    backgroundImage: file(relativePath: { eq: "AskEmSearch1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    allManageTopicsYaml {
      edges {
        node {
          id
          title
          slug: path
          articles
        }
      }
    }

    localSearchPages {
      index
      store
    }

    allArticlesYaml(filter: { discoverable: { eq: true } }, limit: 13) {
      edges {
        node {
          id
          title
          slug: path
        }
      }
    }
  }
`

const addLinkPrefixToTopics = (
  data: {
    id: string
    title: string
    slug: string
    articles: string[]
  }[],
  prefix = ""
) => {
  return data.map(item => {
    return { ...item, linkPrefix: prefix }
  })
}

const mapArticlesToTopics = (
  data: {
    id: string
    title: string
    slug: string
    articles: string[]
    linkPrefix: string
  }[]
) => {
  let _map: { [id: string]: any } = {}
  data.forEach(item => {
    item.articles.forEach((articleName: string) => {
      const topicData = {
        topicTitle: item.title,
        topicSlug: item.slug,
        linkPrefix: item.linkPrefix,
      }
      if (_map[articleName]) {
        _map = { ..._map, [articleName]: [..._map[articleName], topicData] }
        return
      }
      _map = { ..._map, [articleName]: [topicData] }
    })
  })
  return _map
}

const Search = (props: any) => {
  const articleTopics = props.data.allTopicsYaml.edges.map(
    (item: any) => item.node
  )
  const allManageTopicsYaml = props.data.allManageTopicsYaml.edges.map(
    (item: any) => item.node
  )
  const index = props.data.localSearchPages.index
  const store = props.data.localSearchPages.store

  const defaultArticles = props.data.allArticlesYaml.edges.map(
    (item: any) => item.node
  )

  return (
    <Layout>
      <SEO title="Home" />
      <SearchPage
        defaultArticles={defaultArticles}
        articlesToTopicsMap={mapArticlesToTopics([
          ...addLinkPrefixToTopics(allManageTopicsYaml),
          ...addLinkPrefixToTopics(articleTopics, "/articles"),
        ])}
        index={index}
        store={store}
        key={index}
        backgroundImage_Fluid={props.data.backgroundImage.childImageSharp.fluid}
      />
    </Layout>
  )
}

export default Search
