import BackgroundImage from "gatsby-background-image"
import React, { useState } from "react"
// @ts-ignore
import { useFlexSearch } from "react-use-flexsearch"
import { FaSearch } from "../../AppReactIcons"
import { DarkAppButton } from "../buttons"
import ArticlesList from "./ArticlesList"

interface iSearchPage {
  articlesToTopicsMap: { [id: string]: any }
  index: any
  store: any
  defaultArticles: {
    id: string
    slug: string
    title: string
  }[]
  backgroundImage_Fluid: any
}

const makeArticleList = (
  data: {
    id?: string
    slug: string
    title: string
  }[],
  articlesToTopicsMap: { [id: string]: any }
) => {
  return data.reduce((acc: any, item: any) => {
    const topics = articlesToTopicsMap[item.slug]
    if (!topics || !topics.length) return acc
    return [
      ...acc,
      ...topics.map((topic: any) => {
        console.log("test " + `${topic.linkPrefix}/${item.slug}`)
        return {
          articleTitle: item.title,
          topicTitle: topic.topicTitle,
          articleSlug: item.slug,
          topicSlug: topic.topicSlug,
          path: `${topic.linkPrefix}/${item.slug}`,
          // path: `/search?{topic=${item.id}}`,
          // `/discover?topic=${node.id}`
        }
      }),
    ]
  }, [])
}

const SearchPage: React.FC<iSearchPage> = ({
  articlesToTopicsMap,
  index,
  store,
  defaultArticles,
  backgroundImage_Fluid,
}) => {
  const [query, setQuery] = useState<string | null>(null)
  const result = useFlexSearch(query, index, store)
  return (
    <>
      <BackgroundImage
        Tag="section"
        fluid={backgroundImage_Fluid}
        backgroundColor={`gray`}
      >
        <div className="flex items-center justify-center py-12 px-1 sm:py-16 border-b mb-8">
          <div>
            <div className="hidden md:block" style={{ width: "600px" }} />
            <div
              className="hidden sm:block md:hidden"
              style={{ width: "400px" }}
            />
            <div className="block sm:hidden" style={{ width: "90vw" }} />
            <input
              type="text"
              value={query || ""}
              onChange={e => setQuery(e.target.value)}
              className="border text-site-blue-dark py-2 px-4 my-4 rounded-full shadow w-full outline-none"
              autoFocus={true}
              style={{ opacity: 0.95 }}
              placeholder={`Search "What is AskEm?"`}
            />

            <div className="flex items-center justify-center">
              <DarkAppButton Icon={<FaSearch />} title="Search" />
            </div>
          </div>
        </div>
      </BackgroundImage>

      <ArticlesList
        query={query}
        articlesList={makeArticleList(
          query ? result : defaultArticles,
          articlesToTopicsMap
        )}
      />
    </>
  )
}

export default SearchPage
