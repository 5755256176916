import { Link } from "gatsby"
import React from "react"
import { Navbar } from "react-bootstrap"
import styled from "styled-components"
import {
  FaArrowRight,
  FaBookOpen,
  FaFemale,
  FaHeart,
  FaRoad,
  FaStethoscope,
  GiHealthCapsule,
  EarlyMenopause,
  PeriodInline,
  ManagementInline,
  DiagnosistInline,
  HealthtInline,
} from "../../AppReactIcons"
import { DiscoveryPage_InternalLink } from "../../utils/urls"
import { GreenAppButton, PinkAppButton, RedAppButton } from "../buttons"

interface iArticleCard {
  articleTitle: string
  topicTitle: string
  articleSlug: string
  topicSlug: string
  path: string
}

interface iArticlesList {
  articlesList: iArticleCard[]
  query: string | null
}

const Container = styled(Navbar)`
  /* height: 106px;
  background-color: white !important;
  
  z-index: 1000; */
  justify-content: center;
`
const topicSlugToIcon: { [id: string]: any } = {
  "what-is-early-menopause-and-symptoms": <EarlyMenopause />,
  "causes-of-early-menopause": <PeriodInline />,
  diagnosis: <ManagementInline />,
  "health-impacts-of-early-menopause": <DiagnosistInline />,
  "psychological-and-sexual-health": <HealthtInline />,
}
const defaultTopicSlugToIcon = <FaBookOpen />

const ArticleCard: React.FC<iArticleCard> = ({
  articleSlug,
  articleTitle,
  path,
  topicSlug,
  topicTitle,
}) => {
  return (
    <div className="bg-gray-100 border site-border-radius h-full hover:shadow transition duration-100 flex flex-col justify-between">
      <Link to={path} style={{ textDecoration: "none" }}>
        <div className="p-4">
          <div className="flex items-center mb-2">
            <p className="text-site-blue-dark border bg-white text-lg px-2 p-2 mr-3 flex  rounded-full ">
              {topicSlugToIcon[topicSlug] || defaultTopicSlugToIcon}
            </p>
            <p className="text-site-blue-dark">{topicTitle}</p>
          </div>
          <h1 className="text-site-blue-dark text-xl">{articleTitle}</h1>
        </div>
      </Link>
      <div className="flex items-center justify-start border-t mt-2 px-4 py-3">
        <Link to={path} style={{ textDecoration: "none" }}>
          <PinkAppButton Icon={<FaBookOpen />} title="Read article" />
        </Link>
      </div>
    </div>
  )
}

const ArticlesList: React.FC<iArticlesList> = ({ articlesList, query }) => {
  return (
    <Container className="mb-12 sm:mb-16 block m-0 p-0">
      {query && (
        <h1 className="text-site-blue-dark text-xl mb-8">
          Search results for &quot;{query}&quot;
        </h1>
      )}
      {(!articlesList || !articlesList.length) && (
        <div className="text-center bg-gray-200 site-border-radius p-8 my-8">
          <p className="text-gray-600">No search results available</p>
          <h1 className="text-site-blue-dark text-lg">
            Please try to specify the search or write the complete words.
          </h1>
        </div>
      )}
      <div className="grid gap-4 col-span-1 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {articlesList.map((item, index) => {
          return <ArticleCard {...item} key={index} />
        })}
      </div>

      <div className="flex items-center justify-start sm:justify-end mt-8">
        <Link
          to={DiscoveryPage_InternalLink}
          style={{ textDecoration: "none" }}
        >
          <PinkAppButton
            title="Explore all topics"
            Icon={<FaArrowRight />}
            iconOnRight={true}
          />
        </Link>
      </div>
    </Container>
  )
}

export default ArticlesList
